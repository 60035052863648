/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';

import { NavLink } from 'react-router-dom';
import { ReactComponent as SmartMeterIcon } from 'assets/icons/SmartMeter.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/ArrowUp.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDown.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faSignOutAlt, faBell } from '@fortawesome/free-solid-svg-icons';

import { navigate } from 'navigation';
import { I18n } from 'core';
import { authServiceInstance, installationServiceInstance, clientServiceInstance, cupsServiceInstance } from 'services';
import { User, CupsInfo } from 'types/models';

import logoWhite from 'assets/images/logo-white.png';
import logoWhiteTecnics from 'assets/images/logo-white-tecnics.png';

import classNames from 'classnames';
import { Colors } from 'styles';
import { useRole } from 'hooks';
import { ProcessStatus, Roles } from 'types/enums';
import { HeaderSearchInput, Spinner } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalStoreState } from 'types/state';
import { fetchClientCUPSListSuccess, resetClientCUPS, updateClientCUPS } from 'redux/actions/ClientCupsActions';
import { resetCUPS } from 'redux/actions/CupsActions';
import { resetInstallations } from 'redux/actions/InstallationsActions';
import { resetUsers } from 'redux/actions/UsersActions';
import usersService from 'services/remote/UsersService';
import WidgetPermission from 'types/enums/WidgetPermission';
import styles from './Header.module.scss';
import CupsDisplay from './Displays/CupsDisplay';
import InstallationDisplay from './Displays/InstallationDisplay';
import UserDisplay from './Displays/UserDisplay';
import { CommunityDisplay } from './Displays/comunidad-display';
import SearchInputNIF from '../HeaderSearchInput/SearchInputNIF';

const TecnicHeader = ({ permisos, user }: { permisos: string[]; user: User | null }): JSX.Element | null => {
  const role = useRole();

  const instSelected = useSelector((state: GlobalStoreState) => state.installations.selectedInstallation);
  const instSelectedStatus = useSelector((state: GlobalStoreState) => state.installations.selectedInstallationStatus);

  const cupsSelected = useSelector((state: GlobalStoreState) => state.cups.selectedCups);
  const cupsSelectedStatus = useSelector((state: GlobalStoreState) => state.cups.selectedCupsStatus);

  const userSelected = useSelector((state: GlobalStoreState) => state.users.selectedUser);
  const userSelectedStatus = useSelector((state: GlobalStoreState) => state.users.selectedUserStatus);

  const comunidadSelected = useSelector((state: GlobalStoreState) => state.communities.selectedCommunity);
  const comunidadSelectedStatus = useSelector((state: GlobalStoreState) => state.communities.selectedCommunityStatus);
  const permisosShowSearch = [
    WidgetPermission.ACCESO_WIDGET_INSTALACIONES,
    WidgetPermission.ACCESO_WIDGET_CUPS,
    WidgetPermission.ACCESO_WIDGET_USUARIOS,
    WidgetPermission.ACCESO_WIDGET_COMUNIDADES,
  ];
  const hasSearchPermissions =
    permisos && permisos.some((permission) => permisosShowSearch.includes(permission as WidgetPermission));

  return (
    <div className={styles.TecnicHeader}>
      <>
        {/* Logica renderización en Modo Comunidad */}
        {(() => {
          if (role !== Roles.COMMUNITY) return null;
          if (comunidadSelectedStatus === ProcessStatus.LOADING) {
            return <Spinner size={30} />;
          }
          if (comunidadSelectedStatus === ProcessStatus.ERROR) {
            return <span>Error fetching data.</span>;
          }
          if (comunidadSelected) {
            return (
              <CommunityDisplay
                name={comunidadSelected.nombre}
                cau={comunidadSelected.cau || ''}
                address={comunidadSelected.domicilio}
              />
            );
          }
          return <span />;
        })()}
        {/* Logica renderización en Modo CUPS */}
        {(() => {
          if (role !== Roles.CUPS) return null;
          if (cupsSelectedStatus === ProcessStatus.LOADING) {
            return <Spinner size={30} />;
          }
          if (cupsSelectedStatus === ProcessStatus.ERROR) {
            return <span>Error fetching data.</span>;
          }
          if (cupsSelected) {
            return (
              <CupsDisplay
                name={cupsSelected.nombre}
                surname={cupsSelected.apellidos || ''}
                cups={cupsSelected.cups}
                address={cupsSelected.domicilio}
                city={cupsSelected.poblacion}
                cp={cupsSelected.cp}
              />
            );
          }
          return null;
        })()}

        {/* Logica renderización en Modo Instalacion */}
        {(() => {
          if (role !== Roles.INSTALLATION) return null;
          if (instSelectedStatus === ProcessStatus.LOADING) {
            return <Spinner size={30} />;
          }
          if (cupsSelectedStatus === ProcessStatus.ERROR) {
            return <span>Error fetching data</span>;
          }
          if (instSelected) {
            return (
              <InstallationDisplay
                name={instSelected.nombre}
                installation={instSelected.cau || ''}
                address={instSelected.domicilio}
                city={instSelected.poblacion}
              />
            );
          }
          return null;
        })()}

        {/* Logica renderización en Modo Usuario */}
        {(() => {
          if (role !== Roles.USER) return null;
          if (userSelectedStatus === ProcessStatus.LOADING) {
            return <Spinner size={30} />;
          }
          if (userSelectedStatus === ProcessStatus.ERROR) {
            return <span>Error fetching data</span>;
          }
          if (userSelected) {
            return (
              <UserDisplay
                email={userSelected.email}
                name={userSelected.nombre}
                surname={userSelected.apellidos}
                nif={userSelected.nif}
              />
            );
          }
          return null;
        })()}
        {(() => {
          if (role === Roles.ROLE_COMMUNITYENROLLED) {
            return <span />;
          }
          return null;
        })()}
        {(() => {
          if (role === Roles.DEFAULT || role === Roles.MATCHER || role === Roles.ROLE_PERMISIONS) {
            return <span />;
          }
          return null;
        })()}
      </>
      {}
      <div className={styles.search_wrapper}>
        {(() => {
          if ((user && user.tipoDeUsuario === 'admin') || hasSearchPermissions) {
            return <HeaderSearchInput />;
          }
          return null;
        })()}
      </div>
    </div>
  );
};

const ClientHeader = (): JSX.Element | null => {
  // Refs
  const clientDropRef = useRef<HTMLHeadingElement>(null);
  // Hooks
  const dispatch = useDispatch();
  const newCupsList = useSelector((state: GlobalStoreState) => state.clientCUPS.cupsList);
  const newCupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS.selectedCups);

  // State
  const [searchNIF, setSearchNIF] = useState('');

  const [showClientDropdown, setShowClientDropdown] = useState<boolean>(false);

  const handleClickOutsideClient = (evt: any): void => {
    if (clientDropRef.current && !clientDropRef.current.contains(evt.target)) {
      setShowClientDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideClient, true);
    return (): void => {
      document.removeEventListener('click', handleClickOutsideClient, true);
    };
  });

  let isCupsEmpty = true;

  if (newCupsSelected) {
    isCupsEmpty = Object.keys(newCupsSelected).length === 0;
  } else {
    return null;
  }

  const onCupsClick = (cups: CupsInfo): void => {
    setSearchNIF('');
    if (cups !== newCupsSelected) {
      dispatch(updateClientCUPS(cups));
    }
    setShowClientDropdown(false);
  };

  // Filtrar por NIF
  const filteredCups = newCupsList?.filter((cups) => cups.nif.toLowerCase().includes(searchNIF.toLowerCase()));

  return (
    <>
      <div className={styles.ClientHeader}>
        <div ref={clientDropRef} className={styles.selector}>
          <button
            type="button"
            className={classNames(styles.button, {
              [styles.toggled]: showClientDropdown,
            })}
            aria-haspopup="true"
            aria-expanded="false"
            onClick={(): void => setShowClientDropdown(!showClientDropdown)}
          >
            <div className={styles.logo}>
              <SmartMeterIcon height={20} width={20} fill={Colors.COLOR_ELECSUMGREEN} />
            </div>
            <div className={styles.info}>
              <span className={styles.name}>
                {newCupsSelected && !isCupsEmpty ? `${newCupsSelected.nombre} ${newCupsSelected.apellidos}` : ''}
              </span>
              <span className={styles.cups}>{newCupsSelected && !isCupsEmpty ? newCupsSelected.cups : ''}</span>
              <span className={styles.address}>
                {newCupsSelected && !isCupsEmpty
                  ? `${newCupsSelected.domicilio} · ${newCupsSelected.poblacion} ${
                      newCupsSelected.codigoPostal && '·'
                    } ${newCupsSelected.codigoPostal}`
                  : ''}
              </span>
            </div>
            <div className={styles.toggleDropwdown}>
              {showClientDropdown ? (
                <ArrowDownIcon height={16} width={16} fill={Colors.COLOR_WHITE} />
              ) : (
                <ArrowUpIcon height={16} width={16} fill={Colors.COLOR_WHITE} />
              )}
            </div>
          </button>
          <div
            className={classNames(styles.dropdown, {
              [styles.show]: showClientDropdown,
            })}
          >
            <h6 className={styles['dropdown-header']}>{I18n.t('activeCups')}</h6>
            {/* Input de búsqueda */}
            <SearchInputNIF placeholder={I18n.t('searchByNIF')} onSearch={setSearchNIF} />

            <div className={classNames(styles.items)}>
              {filteredCups && filteredCups.length > 0 ? (
                filteredCups.map((cups) => (
                  <div className={styles.item} key={cups.cups}>
                    <input
                      type="radio"
                      name="cupsSelected"
                      id={cups.cups}
                      onChange={(): void => onCupsClick(cups)}
                      checked={newCupsSelected ? newCupsSelected.cups === cups.cups : false}
                    />
                    <label htmlFor={cups.cups}>
                      {cups.propietario !== undefined && cups.propietario === 'propietario' && (
                        <span>{I18n.t('Propietario')}:</span>
                      )}
                      {cups.autorizados !== undefined && cups.autorizados === 'autorizados' && (
                        <span>
                          {I18n.t('Autorizado')}: {cups.nombre} {cups.apellidos}.
                        </span>
                      )}
                      <span> {cups.domicilio}</span>

                      {cups.nif && <span> NIF: {cups.nif}</span>}
                    </label>
                  </div>
                ))
              ) : (
                <p className={styles.noResults}>{I18n.t('NotFound')}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// TODO
const PromotorHeader = (): JSX.Element | null => {
  // Refs
  const clientDropRef = useRef<HTMLHeadingElement>(null);
  // Hooks
  const [user] = useState<User | null>(authServiceInstance.user);

  // State
  const [showClientDropdown, setShowClientDropdown] = useState<boolean>(false);

  const handleClickOutsideClient = (evt: any): void => {
    if (clientDropRef.current && !clientDropRef.current.contains(evt.target)) {
      setShowClientDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideClient, true);
    return (): void => {
      document.removeEventListener('click', handleClickOutsideClient, true);
    };
  });

  return (
    <>
      <div className={styles.ClientHeader}>
        <div ref={clientDropRef} className={styles.selector}>
          <button
            type="button"
            className={classNames(styles.button, {
              [styles.toggled]: showClientDropdown,
            })}
            aria-haspopup="true"
            aria-expanded="false"
            disabled
          >
            <div className={styles.logo}>
              <SmartMeterIcon height={20} width={20} fill={Colors.COLOR_ELECSUMGREEN} />
            </div>
            <div className={styles.info}>
              <span className={styles.name}>{user && `${user.nombre} ${user.apellidos}`}</span>
              <span className={styles.cups}>{user && user.email}</span>
              <span className={styles.promotor}>{user && user.tipoDeUsuario}</span>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

const Header = (): JSX.Element => {
  const profileDropdownRef = useRef<HTMLHeadingElement>(null);
  const [showLogoutDrop, setShowLogoutDrop] = useState<boolean>(false);
  const [user] = useState<User | null>(authServiceInstance.user);
  const [permisos, setPemisos] = useState<string[]>(authServiceInstance.permisos);
  const dispatch = useDispatch();
  const cupsSelected = useSelector((state: GlobalStoreState) => state.clientCUPS);

  const handleClickOutsideLogout = (evt: any): void => {
    if (profileDropdownRef.current && !profileDropdownRef.current.contains(evt.target)) {
      setShowLogoutDrop(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideLogout, true);
    return (): void => {
      document.removeEventListener('click', handleClickOutsideLogout, true);
    };
  });

  const handleLogout = (): void => {
    authServiceInstance.logOut();
    installationServiceInstance.installation = null;
    clientServiceInstance.client = null;
    clientServiceInstance.clients = [];
    cupsServiceInstance.cups = null;
    cupsServiceInstance.cupsList = null;
    dispatch(resetClientCUPS());
    dispatch(resetCUPS());
    dispatch(resetInstallations());
    dispatch(resetUsers());
    navigate('/auth/login');
  };

  const getBrandImage = () => {
    if (user) {
      if (user.isAdmin()) {
        return logoWhiteTecnics;
      }
    }
    return logoWhite;
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  // Header podemos obtener los permisos MOCK test
  const getPermisosUser = async () => {
    setIsLoading(true);

    if (user) {
      const response = await usersService.getPermisos(user.nif);
      // const permisos = ['acceso_web_tecnicos'];
      authServiceInstance.setUserPermisos(response);
      setPemisos(response);
      const firstResponse = await cupsServiceInstance.getCupsFromUserId(user.nif);
      const hasPermission = response.includes(WidgetPermission.ACCESO_CUPS_AUTORIZADOS);
      let secondResponse: any[] = [];
      if (hasPermission) {
        secondResponse = await usersService.getCupsAutorizados(user.nif);
      }

      // Remove duplicates, keeping entries from firstResponse
      const uniqueCupsMap = new Map();
      firstResponse.forEach((cup) => uniqueCupsMap.set(cup.cups, { ...cup, propietario: 'propietario' }));
      secondResponse.forEach((cup) => {
        if (!uniqueCupsMap.has(cup.cups)) {
          uniqueCupsMap.set(cup.cups, { ...cup, autorizados: 'autorizados' });
        }
      });

      const allCups = Array.from(uniqueCupsMap.values());

      const cupsById = allCups.find((item) => item.cups === cupsSelected.selectedCups?.cups);
      dispatch(updateClientCUPS(cupsById || allCups[0]));
      dispatch(fetchClientCUPSListSuccess(allCups));
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const getHomeRedirect = (): string => {
    if (user?.tipoDeUsuario === 'admin' || (permisos && permisos.includes(WidgetPermission.ACCESO_WEB_TECNICOS))) {
      return `/tecnic/dashboard`;
    }
    if (user?.tipoDeUsuario === 'promotor') {
      return `/promotor/${user.nif}/home`;
    }
    if (cupsSelected.selectedCups?.cups) {
      return `/client/${cupsSelected.selectedCups?.cups}/home`;
    }
    return '/client/home';
  };

  useEffect(() => {
    if (user?.tipoDeUsuario !== 'admin') {
      getPermisosUser();
    } else {
      setIsLoading(false);
    }
  }, [user]);

  return (
    <nav className={styles.root}>
      <NavLink className={styles.brand} to={getHomeRedirect()}>
        <img src={getBrandImage()} alt="" />
      </NavLink>
      <div className={styles.content}>
        {isLoading ? (
          <div className={styles.spinner}>
            <Spinner icon size={30} />
          </div>
        ) : (
          <>
            {user?.tipoDeUsuario === 'user' && <ClientHeader />}

            {(user?.tipoDeUsuario === 'admin' ||
              (permisos && permisos.includes(WidgetPermission.ACCESO_WEB_TECNICOS))) && (
              <TecnicHeader permisos={permisos} user={user} />
            )}

            {user?.tipoDeUsuario === 'promotor' && <PromotorHeader />}
          </>
        )}
      </div>

      {/* Icons right */}
      <div className={styles.cornerContainer}>
        <ul>
          {user?.isAdmin() || (permisos && permisos.includes('acceso_widget_alertas')) ? (
            <li className={styles.notifications}>
              <NavLink className={styles.button} to="/tecnic/alerts">
                <FontAwesomeIcon icon={faBell} />
              </NavLink>
            </li>
          ) : null}
          <li className={styles.profileDropdown}>
            <div ref={profileDropdownRef} className={styles.dropdown}>
              <button onClick={(): void => setShowLogoutDrop(!showLogoutDrop)} type="button" className={styles.head}>
                <FontAwesomeIcon icon={faUserCircle} />
              </button>
              <div
                className={classNames(styles.items, {
                  [styles.show]: showLogoutDrop,
                })}
              >
                <NavLink className={styles.item} to="/profile">
                  <FontAwesomeIcon icon={faUserCircle} />
                  {I18n.t('My Profile')}
                </NavLink>
                <button type="button" className={classNames(styles.item, styles.logout)} onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  {I18n.t('Logout')}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
