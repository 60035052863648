import { CupsInfoAPI } from '../types/models';

const CUPSINFO_MOCK: CupsInfoAPI = {
  cups: 'ES0177000000293700TZ0F',
  nombre: 'Carlos',
  apellidos: 'Arguiñano Pelaez',
  codigoPostal: '17230',
  telefono: '9981254222',
  domicilio: 'CATALUNYA,PL.,  6',
  esComunidad: 'si',
  poblacion: 'CALDES DE MONTBUI',
  provincia: 'Barcelona',
  fotovoltaica: 'si',
  modem: 'si',
  esTarifaIndexada: 'si',
  esClienteECE: 'si',
  nif: 'MOCKNIF',
};

const CUPSINFO_LIST_MOCK: CupsInfoAPI[] = [CUPSINFO_MOCK];

export default CUPSINFO_MOCK;
export { CUPSINFO_LIST_MOCK };
