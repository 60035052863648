import { Serializable } from '../interfaces';

export interface CupsInfoAPI {
  cups: string;
  nombre: string;
  apellidos: string;
  domicilio: string;
  poblacion: string;
  provincia: string;
  codigoPostal: string;
  telefono: string;
  esComunidad: string;
  fotovoltaica: 'si' | 'no';
  modem: 'si' | 'no';
  esTarifaIndexada: 'si' | 'no';
  esClienteECE: 'si' | 'no';
  nif: string;
}

export default class CupsInfo implements Serializable<CupsInfo> {
  cups: string;

  nombre: string;

  apellidos: string;

  domicilio: string;

  poblacion: string;

  provincia: string;

  codigoPostal: string;

  telefono: string;

  esComunidad: string;

  fotovoltaica: string;

  modem: boolean;

  esTarifaIndexada: string;

  esClienteECE: boolean;

  autorizados: string;

  propietario: string;

  nif: string;

  isComunity(): boolean {
    return this.esComunidad === 'si';
  }

  deserialize(input: CupsInfoAPI): CupsInfo {
    if (!input) {
      return this;
    }

    this.cups = input.cups || '';
    this.nombre = input.nombre || '';
    this.apellidos = input.apellidos || '';
    this.domicilio = input.domicilio || '';
    this.poblacion = input.poblacion || '';
    this.provincia = input.provincia || '';
    this.codigoPostal = input.codigoPostal || '';
    this.telefono = input.telefono || '';
    this.esComunidad = input.esComunidad || '';
    // this.fotovoltaica = input.fotovoltaica || 'no';
    this.fotovoltaica = 'si';
    this.modem = input.modem === 'si';
    this.esTarifaIndexada = input.esTarifaIndexada || 'no';
    this.esClienteECE = input.esClienteECE === 'si';
    this.nif = input.nif || '';

    return this;
  }

  deserializeArray(inputArray: Array<CupsInfoAPI>): Array<CupsInfo> {
    const cups: Array<CupsInfo> = inputArray.map((input) => new CupsInfo().deserialize(input));

    return cups;
  }
}
