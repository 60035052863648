import React from 'react';
import { NavItemI, I18n } from 'core';
import { Colors } from 'styles';

import { ReactComponent as HomeIcon } from 'assets/icons/Home.svg';
import { ReactComponent as ChartsIcon } from 'assets/icons/Charts.svg';
import { ReactComponent as InvoicesIcon } from 'assets/icons/Invoices.svg';
import { ReactComponent as SustainabilityIcon } from 'assets/icons/Sustainability.svg';
import { ReactComponent as DocumentsIcon } from 'assets/icons/Documents.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as CIMIcon } from 'assets/icons/CIM.svg';
import { useParams } from 'react-router-dom';
import { CupsInfo, User } from 'types/models';
import WidgetPermission from 'types/enums/WidgetPermission';

const ICON_SIZE = 20;
const iconColor = Colors.COLOR_GRAY_100;
const activeIconColor = Colors.COLOR_ELECSUMGREEN;

const createNavigationLinks = (
  cupsSelected: CupsInfo | null,
  user: User | null,
  permisos: string[],
  // listSolicitudes: Solicitudes[],
): Array<NavItemI> => {
  // Verificar si el usuario es administrador
  const isAdmin = user?.tipoDeUsuario === 'admin';

  // Verificar si el usuario tiene permisos para ver CIM
  const hasPermissionForCIM = permisos.includes(WidgetPermission.ACCESO_CIM_AUTORIZADOS);

  // Verificar si el CUPS es comercializado
  const isComercializado = cupsSelected?.esClienteECE;

  // Lógica para mostrar el enlace del CIM
  const shouldShowCIMLink = !!isAdmin || !!isComercializado || !!hasPermissionForCIM;
  const hasPermissionUpdateTarifa = isAdmin || permisos.includes(WidgetPermission.ACCESO_ACTUALIZAR_TARIFA);

  const hasPermissionCupsAutorizados = isAdmin || permisos.includes(WidgetPermission.ACCESO_CUPS_AUTORIZADOS);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { cupsID } = useParams<{ cupsID: string }>();

  const links: Array<NavItemI> = [
    {
      name: I18n.t('Sidenav.Client.Home'),
      url: cupsID ? `/client/${cupsID}/home` : '/client/home',
      icon: <HomeIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <HomeIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
      client: true,
    },
    {
      name: I18n.t('Sidenav.Client.Charts'),
      url: cupsID ? `/client/${cupsID}/charts` : '/client/charts',
      icon: <ChartsIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <ChartsIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
      client: true,
    },
    {
      name: I18n.t('Sidenav.Client.Invoices'),
      url: cupsID ? `/client/${cupsID}/invoices` : '/client/invoices',
      icon: <InvoicesIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <InvoicesIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
      client: true,
    },
    {
      name: I18n.t('Sidenav.Client.Documents'),
      url: cupsID ? `/client/${cupsID}/documents` : '/client/documents',
      icon: <DocumentsIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <DocumentsIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
      client: true,
    },
    {
      name: I18n.t('Sidenav.Client.Sustainability'),
      url: cupsID ? `/client/${cupsID}/sustainability` : '/client/sustainability',
      icon: <SustainabilityIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <SustainabilityIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
      client: true,
    },

    {
      name: I18n.t('Sidenav.CIM.cim'),
      url: `/client/cim/${cupsID}`,
      client: true,
      icon: <CIMIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <CIMIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
      // SI NO ES COMERCIALIZADORA
      hidden: !shouldShowCIMLink, // Aplicando la condición de la tabla
      // si tienes permiso y no eres cliente ECE  no mostrar
      // hidden: !hasPermission || !hasPermissionUpdateTarifa, // Si tiene los permisos mostrarlo
      children: [
        {
          name: I18n.t('Sidenav.CIM.dashboard'),
          url: `/client/cim/${cupsID}/dashboard`,
          client: true,
        },
        {
          name: I18n.t('Sidenav.CIM.registers'),
          url: `/client/cim/${cupsID}/measurement-registers`,
          client: true,
        },
        ...(cupsSelected?.esTarifaIndexada === 'no'
          ? [
              {
                name: I18n.t('Sidenav.CIM.simulation'),
                url: `/client/cim/${cupsID}/invoice-simulation`,
                client: true,
              },
            ]
          : []),
        ...(cupsSelected?.esClienteECE === true
          ? [
              {
                name: I18n.t('Sidenav.CIM.devices'),
                url: `/client/cim/${cupsID}/measurement-devices`,
                client: true,
              },
            ]
          : []),

        ...(cupsSelected?.modem
          ? [
              {
                name: I18n.t('Sidenav.CIM.optimization'),
                url: `/client/cim/${cupsID}/power-optimization`,
                client: true,
              },
            ]
          : []),
        {
          name: I18n.t('Sidenav.CIM.closures'),
          url: `/client/cim/${cupsID}/closures`,
          client: true,
        },
        {
          name: I18n.t('Sidenav.CIM.export'),
          url: `/client/cim/${cupsID}/data-export`,
          client: true,
        },
      ],
    },

    {
      name: I18n.t('Sidenav.UpdateTarifa.mas'),
      url: cupsID ? `/client/${cupsID}/updateTarifa` : '/client/updateTarifa',
      client: true,
      icon: <MoreIcon width={ICON_SIZE} height={ICON_SIZE} fill={iconColor} />,
      activeIcon: <MoreIcon width={ICON_SIZE} height={ICON_SIZE} fill={activeIconColor} />,
      children: [
        {
          name: I18n.t('Sidenav.UpdateTarifa.updateTarifa'),
          url: `/client/${cupsID}/updateTarifa`,
          hidden: !hasPermissionUpdateTarifa, // Si tiene los permisos mostrarlo
          client: true,
        },
        {
          name: I18n.t('Sidenav.cupsAutorizados'),
          url: `/client/${cupsID}/solicitud-autorizacion-cups-terceros`,
          hidden: !hasPermissionCupsAutorizados,
          client: true,
        },
      ],
    },
  ];
  return links;
};

export default createNavigationLinks;
