import React, { useState, useRef } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { Colors } from 'styles';
import styles from './HeaderSearchInput.module.scss';

interface SearchInputProps {
  placeholder: string;
  onSearch: (query: string) => void;
}

const SearchInputNIF: React.FC<SearchInputProps> = ({ placeholder, onSearch }: SearchInputProps) => {
  const [inputText, setInputText] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <SearchIcon height={18} width={18} fill={Colors.COLOR_GRAY_500} />
        <input
          ref={searchInputRef}
          value={inputText}
          placeholder={placeholder}
          onChange={handleInputChange}
          className={styles.input}
          type="text"
        />
      </div>{' '}
    </div>
  );
};

export default React.memo(SearchInputNIF);
